/*
| ===================================================
| This File is for the listing of Conventions
| ===================================================
*/
<template>
  <v-layout wrap justify-start>
    <v-flex xs12>
      <v-layout wrap justify-center>
        <v-flex xs12 pb-12>
          <v-img src="@/assets/products-grey.svg" cover height="60vh">
            <v-layout wrap justify-start fill-height>
              <v-flex xs12 text-center align-self-center>
                <div class="page-heading dark">
                  Cb Digital ha nel tempo promosso l’accesso digitale al mondo assicurativo grazie a convenzioni con differenti Enti e/o aziende, mettendo a loro disposizione le migliori offerte e prodotti presenti sul mercato assicurativo
                </div>
              </v-flex>
            </v-layout>
          </v-img>
        </v-flex>
      </v-layout>
      <v-layout wrap justify-center class="product-container">
        <v-flex xs12 md9 lg6>
          <v-layout wrap justify-center>
            <v-flex xs12 sm6 md6 pa-4 v-for="(item,j) in items" :key="j" style="align-items: stretch;">
              <AppCard class="pa-6" style="min-height: 100%; display: flex; flex-direction: column;">
              <v-flex xs12 class="card-title">
                    {{ item.name }}
                  </v-flex>
                  <v-flex xs12 py-2 class="normal-text">
                    <span v-html="item.description"></span>
                  </v-flex>
                  <v-flex xs12 pt-2 style="margin-top: auto;">
                    <a :href="item.url" target="_blank">
                      <v-btn color="primary" dark :ripple="false" depressed>
                        <span class="text-capitalize">Accedi al portale</span>
                      </v-btn>
                    </a>
                  </v-flex>
              </AppCard>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</template>

<script>
import AppCard from "@/components/shared/AppCard";
import {mapGetters} from "vuex";

export default {
  name: "Conventions",
  components: {
    AppCard,
  },
  computed: {
    ...mapGetters({
      items: "getConventions",
    })
  }
}
</script>